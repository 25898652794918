<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter justify-center items-center">
    <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div class="full-page-bg-color" slot="no-body">
          <div class="vx-row no-gutter">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img alt="login" class="mx-auto" src="@/assets/images/pages/lock-screen.png">
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Authorizing Discord</h4>
                </div>
                <vs-input class="w-full mb-6" disabled icon="icon icon-lock" icon-no-border icon-pack="feather" value="rapidportal"/>
                <vs-input class="w-full mb-6" disabled icon="icon icon-user" icon-no-border icon-pack="feather" value="identify email guilds guilds.join"/>

                <div class="flex justify-between flex-wrap">
                  <router-link class="mb-4" to="/login">Are you not {{ activeUserInfo.full_name }}?</router-link>
                </div>

              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    async authorizeApplication () {
      this.$vs.loading()
      if (this.$route.query.code === undefined) {
        this.$http
          .get('discord/auth', {
            maxRedirects: 0
          })
          .then(response => {
            this.$vs.loading.close()

            if (response.data && response.data.data.url) {
              location.href = response.data.data.url

              this.$gtag.event('auth_discord_success', {
                event_category: 'authentication',
                event_label: 'auth_discord_success',
              });

              return this.$vs.notify({
                title: 'Authorizing with Discord',
                text: 'Redirecting you to the Discord Web Client...',
                color: 'success'
              })
            }

          })
          .catch(e => {
            this.$vs.loading.close()

            this.$gtag.event('auth_discord_failure', {
              event_category: 'authentication',
              event_label: 'auth_discord_failure',
            });

            return this.$vs.notify({
              title: 'Failed to Authorize',
              text: 'An error has occurred while authorizing you with Discord',
              color: 'success'
            })
          })
        return
      }

      const URL_PARAMS = new URLSearchParams(this.$route.query)

      this.$vs.loading()

      this.$http
        .get(`discord/auth?${URL_PARAMS}`, {
          maxRedirects: 0
        })
        .then(response => {
          this.$vs.loading.close()
          if (response.response) response = response.response

          if (response.data) {
            location.href = 'https://discordapp.com/channels/746194470273286216/746220348713730118'
            return this.$vs.notify({
              title: 'Authorized with Discord',
              text: 'Redirecting you to the Discord Web Client...',
              color: 'success'
            })
          }

          return this.$vs.notify({
            title: 'Failed to Authorize',
            text: 'An unknown error has occurred while authorizing your profile with Discord.',
            color: 'danger'
          })
        })
        .catch(error => {
          this.$vs.loading.close()
          return this.$vs.notify({
            title: 'Failed to Authorize',
            text: 'An unknown error has occurred while authorizing your profile with Discord.',
            color: 'danger'
          })
        })

    },
    redirectToLogin () {
      this.$router.push({
        path: 'login',
        query: this.$route.query
      })
    }
  },
  created () {

    if (!this.$store.state.auth.isUserLoggedIn()) {
      return this.$router.push({
        path: 'login',
        query: this.$route.query
      })
    }

    this.authorizeApplication()
  }
}

</script>
